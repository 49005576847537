import React from 'react'
import PropTypes from 'prop-types'
import StringValidator from 'validator'

import Dropdown from '../Dropdown/dynamic'
import Button from '../Button/dynamic'
import TextInput from '../TextInput/dynamic'

import { withTranslation } from '../../../i18n'

import ModalUserManagementStyled from './styled'

import CloseIcon from '../../assets/images/notification/close.svg'

export class ModalUserManagement extends React.PureComponent {
  state = {
    email: this.props.userData.email,
    roleId: this.props.userData.roleId,
  }

  handleContentClicked = e => {
    e.stopPropagation()
  }

  handleEmailInputChanged = newValue => {
    const isEmailValid = StringValidator.isEmail(newValue)
    this.setState({
      email: newValue,
      isEmailValid,
    })
  }

  handleRoleInputChanged = newValue => {
    this.setState({
      roleId: newValue,
    })
  }

  shouldDisabledSubmitBtn() {
    const isEmailValid = StringValidator.isEmail(this.state.email)
    const isRoleValid = !!this.state.roleId
    return !(isEmailValid && isRoleValid)
  }

  handleSubmitClicked = () => {
    const data = {
      id: this.props.userData.id,
      email: this.state.email,
      roleId: this.state.roleId,
    }
    this.props.onSubmit(data)
  }

  renderCloseBtn() {
    return (
      <div className="modal-close-btn flex" onClick={this.props.onClose}>
        <img src={CloseIcon}></img>
      </div>
    )
  }

  getEmailErrorText() {
    let errorText = ''
    if (this.state.isEmailValid === false) {
      errorText = this.props.t('email_is_invalid')
    } else if (this.props.initialErrorText) {
      errorText = this.props.initialErrorText
    }
    return errorText
  }

  render() {
    let className = 'modal-user-management-container'
    className += ` ${this.props.className}`
    return (
      <ModalUserManagementStyled
        id={`${this.props.id}-overlay`}
        data-test={`${this.props.id}-overlay`}
        className={this.props.overlayClassName}
        onClick={this.props.onClose}
      >
        <div id={`${this.props.id}-content-box`} data-test={`${this.props.id}-content-box`} className={className} onClick={this.handleContentClicked}>
          {this.renderCloseBtn()}
          <div className="user-management-input-container">
            <div className="title-container">{this.props.userData.id ? this.props.t('edit_user') : this.props.t('add_new_user')}</div>
            <TextInput
              id="user-management-email-input"
              type="text"
              className="user-management-input"
              label={this.props.t('email')}
              placeholder={this.props.t('email')}
              value={this.state.email}
              onChange={this.handleEmailInputChanged}
              errorText={this.getEmailErrorText()}
              disabled={!!this.props.userData.id}
            />
            <Dropdown
              id="user-management-role-dropdown"
              className="user-management-input"
              label={this.props.t('role')}
              dropdownLabel={this.props.t('role')}
              placeholder={this.props.t('select_role')}
              options={this.props.roleOptions}
              value={this.state.roleId}
              onChange={this.handleRoleInputChanged}
            />
          </div>
          <div className="user-management-btn-container">
            <Button
              id="button"
              className="user-management-btn"
              text={this.props.userData.id ? this.props.t('save') : this.props.t('create')}
              onClick={this.handleSubmitClicked}
              disabled={this.shouldDisabledSubmitBtn()}
            />
          </div>
        </div>
      </ModalUserManagementStyled>
    )
  }
}

ModalUserManagement.defaultProps = {
  id: 'user-management-modal',
  className: '',
  overlayClassName: '',
  userData: {
    id: undefined,
    email: '',
    roleId: '',
  },
  initialErrorText: '',
}

ModalUserManagement.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    email: PropTypes.string,
    roleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  initialErrorText: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(ModalUserManagement)
