import styled from 'styled-components'

export default styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #121212e8;
  z-index: 10;
  .modal-user-management-container {
    width: 400px;
    padding: 40px;
    background: #1e1e1e;
    color: white;
    border: 1px solid #fbf8f8;
    .modal-close-btn {
      justify-content: flex-end;
      margin-top: -21px;
      margin-right: -21px;
      margin-bottom: 17px;
      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
    .title-container {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: #fafafa;
      margin-bottom: 20px;
    }
    .user-management-input-container {
      margin-bottom: 48px;
      .user-management-input {
        width: 100%;
        margin-bottom: 22px;
      }
    }
    .user-management-btn-container {
      display: flex;
      justify-content: center;
      .user-management-btn {
        width: 180px;
        border-radius: 6px;
      }
    }
  }
`
